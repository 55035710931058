/*
	Stylesheet homepage webu TYMA CZ
*/
.news-item, .info, .info__about-us .tab__labels, .info__about-us, .tab__part-menu, .tab__part-txt, .hp-eshop-menu, .tab__content, .slider-section {
  zoom: 1;
}
.news-item:before, .info:before, .info__about-us .tab__labels:before, .info__about-us:before, .tab__part-menu:before, .tab__part-txt:before, .hp-eshop-menu:before, .tab__content:before, .slider-section:before, .news-item:after, .info:after, .info__about-us .tab__labels:after, .info__about-us:after, .tab__part-menu:after, .tab__part-txt:after, .hp-eshop-menu:after, .tab__content:after, .slider-section:after {
  content: "";
  display: table;
}
.news-item:after, .info:after, .info__about-us .tab__labels:after, .info__about-us:after, .tab__part-menu:after, .tab__part-txt:after, .hp-eshop-menu:after, .tab__content:after, .slider-section:after {
  clear: both;
}

.info__about-us .tab__label:before, .tab__part-menu li:before, .hp-eshop-menu li:before, .hp-tabs-menu .tab__label:before, .slide:before {
  display: none;
}

.hp-tabs-menu .tab--blue {
  background: #007bc4;
  color: #fff;
}
.hp-tabs-menu .tab--blue a {
  color: #fff;
}

/*
        slider
*/
.slider-section {
  position: relative;
  margin-bottom: 35px;
}

.slider {
  position: relative;
  width: calc(100% + 40px);
  height: 0;
  overflow: hidden;
  margin: 0 -20px;
  padding: 0 0 32.8125% 0;
}

.slide {
  position: absolute;
  z-index: 1;
  display: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.slide:first-child {
  display: block;
}
.slide img {
  width: 100%;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 700px) {
  .slider__txt-box {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(-9vw + 378px);
    min-height: initial;
    height: 100%;
    margin: 0;
    padding: 10px 20px 20px;
    border: 0;
    background-color: #fff;
  }
  .slider__txt-box:before {
    position: absolute;
    display: block;
    width: 10px;
    height: 78px;
    top: 0;
    left: -10px;
    background-color: #007bc4;
    content: "";
  }
  .slider__txt-box p:last-child {
    margin: 0;
  }
  .slide h2 {
    background: #fff;
    border: 0;
    margin-top: 0;
    padding: 0;
    color: #161616;
    font-size: 22px;
    font-weight: 400;
  }
  .slide h2 span {
    font-weight: 700;
  }
  .slider-menu {
    display: block;
    position: absolute;
    z-index: 2;
    height: 39px;
    left: calc(106vw + -501px);
    right: auto;
    top: auto;
    bottom: 16px;
    margin: 0;
    padding: 12px 0;
    text-align: center;
  }
  .slider-menu__item {
    background: #fff;
    display: inline-block;
    width: 12px;
    height: 12px;
    overflow: hidden;
    border-radius: 50%;
    padding: 0;
    vertical-align: middle;
    color: #fff;
    font-size: 0;
    box-shadow: 0 0 2px 0 #000;
    cursor: pointer;
  }
  .slider-menu__item:not(:last-child) {
    margin-right: 12px;
  }
  .slider-menu__item:hover, .slider-menu__item.active {
    background: #007bc4;
    color: #007bc4;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 750px) {
  .slider__txt-box {
    height: auto;
  }
  .slide h2 {
    font-size: 1.571rem;
  }
  .slider-menu {
    left: calc(50% - 42px);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 800px) {
  .slider__txt-box {
    width: calc(50vw + -100px);
  }
  .slide h2 {
    margin-bottom: 0.5em;
    font-size: 1.929rem;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1000px) {
  .slider__txt-box {
    width: calc(30vw + 100px);
    padding: 30px 30px 35px 25px;
  }
  .slider__txt-box:before {
    height: 101px;
  }
  .slide h2 {
    font-size: 2.286rem;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1200px) {
  .slider {
    width: 100%;
    margin: 0;
  }
  .slider__txt-box {
    width: 460px;
    padding: 32px 40px 37px 30px;
  }
  .slider__txt-box:before {
    height: 103px;
  }
  .slide {
    margin: 0;
    width: 100%;
  }
}
/*
    komponenty s taby
*/
.tab {
  display: none;
}
.tab.is-visible {
  display: block;
}

/****** styly pro tabs v casti hp-tabs-menu ******/
.hp-tabs-menu .tab__labels {
  margin: 0 -20px;
  padding-left: 0;
  display: flex;
}
.hp-tabs-menu .tab__label {
  flex: 1;
  margin: 0;
  padding: 0;
}
.hp-tabs-menu .tab__link {
  display: block;
  height: 60px;
  padding: 17px 20px 15px 20px;
  color: #161616;
  text-decoration: none;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.hp-tabs-menu .tab__link:hover,
.hp-tabs-menu .tab__label.is-visible .tab__link {
  background: #007bc4;
  color: #fff;
}
.hp-tabs-menu .tab {
  margin: 0 -20px;
}
.hp-tabs-menu .tab--blue {
  padding: 25px 0 10px 0;
}

.hp-eshop-menu {
  margin: 0 0 20px 0;
  padding: 25px 0 10px 20px;
  background: #007bc4;
}
.hp-eshop-menu li {
  margin-bottom: 20px;
  padding: 0 20px 0 0;
}
.hp-eshop-menu a {
  display: block;
  position: relative;
  height: 60px;
  padding: 20px 40px 20px 21px;
  background: #0b8ad5;
  font-size: calc(1.1111111111vw + 12.4444444444px);
  color: #fff;
  text-decoration: none;
  line-height: 1.25;
}
.hp-eshop-menu a:after {
  display: block;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  content: "";
  width: 12px;
  height: 20px;
  background: url(../../gfx/next-w.svg) right center/12px 20px no-repeat;
}
.hp-eshop-menu a:hover, .hp-eshop-menu a:focus {
  background: linear-gradient(rgba(255, 255, 255, 0.11) 3%, rgba(0, 0, 0, 0.11) 97%), #1d4259;
  outline: none;
}
.hp-eshop-menu a br {
  display: none;
}

.tab__part-txt {
  margin-bottom: 10px;
  padding: 0 20px;
}
.tab__part-txt h3 {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 900;
  color: #fff;
}
.tab__part-txt .box {
  font-size: 16px;
}

.tab__part-menu h4 {
  margin: 0.3em 0 0.4em;
  font-size: 1.286rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.1;
}
.tab__part-menu ul {
  margin: 0 0 0 20px;
  padding: 0;
}
.tab__part-menu li {
  margin-bottom: 20px;
  padding: 0 20px 0 0;
}
.tab__part-menu a {
  display: block;
  position: relative;
  height: 90px;
  padding: 14px 60px 20px 20px;
  background: #0b8ad5;
  color: #fff;
  text-decoration: none;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.tab__part-menu a:after {
  display: block;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  content: "";
  width: 15px;
  height: 25px;
  background: url(../../gfx/next-w.svg) right center no-repeat;
}
.tab__part-menu a:hover, .tab__part-menu a:focus {
  background: linear-gradient(rgba(255, 255, 255, 0.11) 3%, rgba(0, 0, 0, 0.11) 97%), #1d4259;
  outline: none;
}
.tab__part-menu p {
  margin-bottom: 0;
  margin-right: -2px;
}

/****** styly pro tabs v casti info__about-us ******/
.info__about-us .tab__labels {
  display: none;
}
.info__about-us .tab__label {
  float: left;
  margin: 0;
  padding: 0;
}
.info__about-us .tab__link {
  display: block;
  margin: 0 -1px -1px 0;
  padding: 19px 15px 17px;
  border: 1px solid transparent;
  color: #161616;
  text-decoration: none;
  line-height: 1.3;
}
.info__about-us .tab__link:hover,
.info__about-us .tab__label.is-visible .tab__link {
  background: #fff;
  border: 1px solid #efefef;
  color: #007bc4;
}
.info__about-us .tab__label.is-visible .tab__link {
  border-bottom: 1px solid #fff;
}
.info__about-us .tab {
  border-bottom: 0;
  padding: 20px 15px;
}
.info__about-us .tab:last-child {
  border-bottom: 1px solid #dadada;
}
.info__about-us h2 {
  margin: 8px 0 18px 0;
  font-size: 1.143rem;
  font-weight: 900;
}
.info__about-us ul {
  padding-left: 0;
}

.info__img {
  margin: 12px 0 26px 0;
}

.about-us__logos a {
  display: inline-block;
  margin: 5px 5% 5px 0;
  padding: 10px;
}
.about-us__logos a:last-of-type {
  margin-right: 0;
}
.about-us__logos img {
  vertical-align: middle;
}

.open-tab + .tab {
  display: block;
  padding: 15px;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
}

.tab__title {
  border-bottom: 0;
}
.tab__title:last-of-type, .tab__title.open-tab {
  border-bottom: 1px solid #dadada;
}
.tab__title.open-tab {
  color: #007bc4;
}

.tab__title.open-tab:before {
  display: none;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 450px) {
  .hp-tabs-menu .tab__label {
    flex: 0 1 auto;
  }
  .hp-tabs-menu .tab__link {
    height: 56px;
    padding-top: 18px;
    padding-bottom: 17px;
  }
  .tab__part-txt {
    padding: 0 25px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .hp-tabs-menu .tab__labels,
  .hp-tabs-menu .tab {
    margin: 0;
  }
  .hp-eshop-menu__canvas {
    background-color: #007bc4;
    position: relative;
    margin: 0 0 20px 0;
    padding: 30px 0 45px 0;
  }
  .hp-eshop-menu {
    height: 592px;
    margin: 0;
    padding: 0 0 0 20px;
    overflow: hidden;
  }
  .hp-eshop-menu li {
    float: left;
    width: 50%;
  }
  .hp-eshop-menu li a {
    position: relative;
    height: 82px;
    padding-right: 15px;
    font-size: 17px;
  }
  .hp-eshop-menu li a:after {
    width: 15px;
    height: 25px;
    background-size: auto;
  }
  .hp-eshop-menu li a.one-row {
    padding-top: 31px;
  }
  .hp-eshop-menu li a br {
    display: inline;
  }
  .hp-eshop-menu--expanded {
    height: auto;
    overflow: visible;
    margin: 0 0 -20px 0;
  }
  .hp-eshop-menu__toggle {
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translateX(-50%);
    cursor: pointer;
    display: block;
    width: 25px;
    height: 15px;
    opacity: 0.5;
  }
  .hp-eshop-menu__toggle:hover {
    opacity: 1;
  }
  .hp-eshop-menu__toggle.hp-eshop-menu__toggle--more {
    background: url(../../gfx/arrow-down-w.svg) 50%;
  }
  .hp-eshop-menu__toggle.hp-eshop-menu__toggle--less {
    background: url(../../gfx/arrow-up-w.svg) 50%;
  }
  .tab__part-menu li {
    float: left;
    width: 50%;
  }
  .tab__part-menu a {
    height: calc(-13.3333333333vw + 196.6666666667px);
    padding-right: 52px;
  }
  .tab__title {
    display: none;
  }
  .info__about-us .tab__labels {
    display: flex;
    margin: 0;
    padding-left: 0;
  }
  .info__about-us .tab__label {
    flex: initial;
  }
  .info__about-us .tab {
    border: 1px solid #efefef;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .tab__part-menu a {
    padding-right: 65px;
  }
  .info__about-us .tab {
    padding: 20px;
  }
  .tab__content__right {
    float: right;
    width: calc(100% - 251px);
  }
  .info__img {
    width: 231px;
    height: 191px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 750px) {
  .hp-eshop-menu {
    height: 388px;
  }
  .hp-eshop-menu li {
    width: 33.3333333%;
  }
  .hp-eshop-menu.hp-eshop-menu--expanded {
    height: auto;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 800px) {
  .hp-tabs-menu .tab--blue {
    padding: 30px 0 10px 0;
  }
  .tab__part-txt {
    margin-bottom: 0;
    padding: 0 20px 0 30px;
    float: left;
    width: 50%;
  }
  .tab__part-menu {
    float: left;
    width: 50%;
  }
  .tab__part-menu li {
    float: none;
    width: auto;
  }
  .tab__part-menu a {
    height: 90px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .hp-tabs-menu .tab__link {
    padding-right: 35px;
    padding-left: 35px;
  }
  .tab__part-txt {
    width: 45%;
  }
  .tab__part-txt .box {
    font-size: 1.071rem;
  }
  .tab__part-menu {
    width: 55%;
  }
  .tab__part-menu li {
    float: left;
    width: 50%;
  }
  .tab__part-menu a {
    height: 130px;
    padding-right: 51px;
  }
  .info__about-us .tab__link {
    padding-right: calc(7.5vw + -47.5px);
    padding-left: calc(7.5vw + -47.5px);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 950px) {
  .hp-eshop-menu {
    height: 286px;
  }
  .hp-eshop-menu li {
    width: 25%;
  }
  .hp-eshop-menu.hp-eshop-menu--expanded {
    height: auto;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 1100px) {
  .hp-eshop-menu {
    height: 184px;
  }
  .hp-eshop-menu li {
    width: 20%;
  }
  .hp-eshop-menu.hp-eshop-menu--expanded {
    height: auto;
  }
  .tab__part-txt {
    width: 58.9743589744%;
    padding-right: 0;
    padding-left: 40px;
  }
  .tab__part-txt .box {
    float: left;
    width: 50%;
    padding-right: 40px;
  }
  .tab__part-txt h3 {
    margin: 18px 50% 30px 0;
  }
  .tab__part-menu {
    float: left;
    width: 41.0256410256%;
  }
  .tab__part-menu ul {
    margin: 0;
  }
  .tab__part-menu a {
    height: 120px;
  }
  .tab__part-menu p {
    font-size: 0.929rem;
  }
  .info__about-us .tab__link {
    padding-right: 35px;
    padding-left: 35px;
  }
}
/*
    cast info (news a about us na hp)
 */
.info__news h2 {
  margin: 20px 0 18px;
  font-size: 1rem;
}

.news-item {
  padding: 28px 0 10px 0;
  border-top: 1px solid #cce5f3;
}
.news-item__img {
  display: none;
}
.news-item h3 {
  margin: -3px 0 8px 0;
  font-size: 1.143rem;
  font-weight: 900;
}
.news-item h3 a {
  color: #007bc4;
  text-decoration: none;
}
.news-item h3 a:hover, .news-item h3 a:focus {
  color: #1d4259;
}
.news-item .date {
  color: #007bc4;
  margin: 0;
}

.selfpromo-box {
  margin-top: 40px;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .news-item__img {
    display: block;
    float: left;
    width: 100px;
    margin-top: -1px;
    padding: 0 20px 20px 0;
    opacity: 0.8;
  }
  .news-item__text {
    float: left;
    width: calc(100% - 100px);
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 600px) {
  .info__img {
    float: left;
    margin-right: 20px;
  }
}
/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 900px) {
  .info__about-us {
    float: left;
    width: 58.9744%;
    margin-right: 40px;
  }
  .info__news {
    float: right;
    width: calc(41.0256% - 60px);
    margin-right: 20px;
  }
  .selfpromo-box {
    float: left;
    width: 58.9744%;
    margin: 0;
  }
}

/*# sourceMappingURL=hp.css.map */